import Vue from 'vue/dist/vue.esm'

import AdminTagSelect from '../components/admin_tag_select.vue'
import AdminProductSelect from '../components/admin_product_select.vue'
import AdminTagMultiSelect from '../components/admin_tag_multi_select.vue'
import AdminTagSingleSelect from '../components/admin_tag_single_select.vue'

export default () => {
  document.addEventListener("turbolinks:load", () => {
    if (document.getElementById('post-form')) {
      const chat = new Vue({
        el: '#post-form',
        components: { AdminTagSelect, AdminProductSelect, AdminTagMultiSelect, AdminTagSingleSelect }
      });

      const submit = document.getElementById('form-submit')
      submit.addEventListener("click", () => {
        const agreeCheck = document.getElementById('agree-check')
        if (!agreeCheck.checked) {
          event.preventDefault()
          window.alert('Please check')
        }
      })

      // multi-select
      const selects = document.querySelectorAll('.multi-select-container')
      for (const select of selects) {
        const target = select.querySelector('.user-multi-tag-select')
        const observer = new ResizeObserver(_entries => {
          const height = target.clientHeight + 20
          select.style.height = height + "px"
        })
        observer.observe(target)
      }
    }
  });
};
