export default () => {
  document.addEventListener("turbolinks:load", () => {
    const select = document.getElementById('language-select');

    if (!select) {
      return
    }

    select.addEventListener('change', () => {
      const url = new URL(window.location.href);

      let pathname = url.pathname;
      if(pathname.startsWith('/ja')) {
        pathname = pathname.replace(/^\/ja/, '')
      } else if(pathname.startsWith('/en')) {
        pathname = pathname.replace(/^\/en/, '')
      }
      if(select.value === 'ja') {
        pathname = '/ja' + pathname;
      }

      const newUrl = url.protocol + '//' + url.host + pathname + url.search;
      window.location.href = newUrl;
    });
  });
};
