export default () => {
  document.addEventListener("turbolinks:load", () => {
    const preview = document.querySelector('.user .video-preview');

    if (preview) {
      const checkedUrl = function (url) {
        if (!url) {
          return
        }
        const re = new RegExp('^<iframe.+(youtube\.com/embed/|vimeo\.com/video/).+></iframe>$')
        if (url.trim().match(re)) {
          return url.trim()
        }
      }

      const input = document.getElementById('post_input_video_url')
      input.addEventListener('change', () => {
        const iframe = preview.querySelector('iframe')
        if (iframe) {
          iframe.remove()
        }

        const url = checkedUrl(input.value)
        if (url) {
          preview.insertAdjacentHTML('afterbegin', url);
        }
      });
    }
  });
};
