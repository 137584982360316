<template>
  <div>
    <a :href="`/users/${item.user_id}`">
      <div class="item-user">
        <div class="item-user-image">
          <img :src="item.user_thumb">
        </div>
        <div class="item-user-info">
          <div class="item-user-name">
            <span>{{ item.user_name }}</span>
          </div>
          <div class="item-user-date">
            <span>{{ item.post_date }}</span>
          </div>
        </div>
      </div>
    </a>

    <div class="item-media">
      <a :href="item.url">
        <template v-if="hasVideoThumbnail">
          <div class="item-video" v-html="item.video">
          </div>
          <div class="item-video-cover">
          </div>
        </template>
        <div class="item-image" v-else>
          <img :src="item.image">
        </div>
        <div class="item-credit"  v-if="item.source != null && item.source != ''">
            <span>source: {{ item.source }}</span>
        </div>
       </a>
    </div>

    <div class="item-icons">
      <template v-if="isSignedIn == 'true'">
        <div class="item-like-icon">
          <div @click="updateLike">
            <img :src="likeIconPath">
          </div>
        </div>
        <div class="item-collection-icon">
          <div @click="updateCollection">
            <img :src="collectionIconPath">
          </div>
        </div>
      </template>
      <template v-else-if="isMobile == 'true'">
        <div class="item-like-icon">
          <a href="/user/login">
            <img src="/images/icons/like_button_empty.svg">
          </a>
        </div>
        <div class="item-collection-icon">
          <a href="/user/login">
            <img src="/images/icons/add_to_collection_button.svg">
          </a>
        </div>
      </template>
      <template v-else>
        <div class="item-like-icon">
          <div @click="openSignIn">
            <img src="/images/icons/like_button_empty.svg">
          </div>
        </div>
        <div class="item-collection-icon">
          <div @click="openSignIn">
            <img src="/images/icons/add_to_collection_button.svg">
          </div>
        </div>
      </template>

      <div class="item-type-icon icon-tooltip">
        <img :src="item.icon">
        <span class="tooltip-text">{{ item.tooltip }}</span>
      </div>
    </div>

    <div class="item-description">
      {{ unescapeString(item.description) }}
      <a :href="item.url">{{ seeDetailText }}</a>
    </div>

    <div class="item-tags">
      <a :href="tag.url" v-for="tag in item.tags">
        <div class="item-tag">{{ unescapeString(tag.name) }}</div>
      </a>
    </div>
  </div>
</template>

<script>
const axios = require('axios');

export default {
  props: {
    item: Object,
    seeDetailText: String,
    isSignedIn: String,
    isMobile: String,
  },
  data() {
    return {
      isLiked: false,
      isCollected: false,
    }
  },

  computed: {
    hasVideoThumbnail() {
      if (this.item.type == 'post' && this.item.video != '') {
        return true
      }
      return false
    },

    likeIconPath() {
      if (this.isLiked) {
        return "/images/icons/like_button_full.svg"
      } else {
        return "/images/icons/like_button_empty.svg"
      }
    },

    collectionIconPath() {
      if (this.isCollected) {
        return "/images/icons/added_to_collection_button.svg"
      } else {
        return "/images/icons/add_to_collection_button.svg"
      }
    },
  },

  methods: {
    updateCollection() {
      const action = this.isCollected ? 'remove' : 'add'

      axios.post('/collections', { id: this.item.id, type: this.item.type, required_action: action })
      .then(response => {
      })
      .catch(error => {
        console.log(error)
      });

      this.isCollected = !this.isCollected
    },

    updateLike() {
      const action = this.isLiked ? 'remove' : 'add'

      axios.post('/likes', { id: this.item.id, type: this.item.type, required_action: action })
      .then(response => {
      })
      .catch(error => {
        console.log(error)
      });

      this.isLiked = !this.isLiked
    },

    // Duplicated from modal.js
    closeAllModals() {
      const modals = document.querySelectorAll('.user-modal') || [];
      for (const modal of modals) {
        modal.classList.add('hidden')
      }
    },

    // Duplicated from modal.js
    openSignInModal() {
      const form = document.getElementById('user-signin-form')

      if (form) {
        form.addEventListener('ajax:success', (evt) => {
          const data = evt.detail[0]
          Turbolinks.visit(data.location)
        })

        form.addEventListener('ajax:error', (evt) => {
          form.querySelector('.error').classList.remove('hidden')
        })
      }
    },

    // Duplicated and modified from modal.js
    openSignIn() {
      const target = document.querySelector('.modal-signin')
      if (target) {
        this.closeAllModals()

        const body = document.querySelector('body');
        body.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
      target.classList.remove('hidden')

      this.openSignInModal()
    },

    //
    // unescape HTML character entities
    //
    unescapeString(string) {
      return new DOMParser().parseFromString(string,'text/html').querySelector('html').textContent;
    },
  },

  mounted() {
    // Set default like and collection status
    if (this.item.is_liked) {
      this.isLiked = true
    }
    if (this.item.is_collected) {
      this.isCollected = true
    }
  },
}
</script>
