export default () => {
  document.addEventListener("turbolinks:load", () => {
    const body = document.querySelector('body');
    const navigation = document.querySelector('.user.mobile .bottom-navigation')

    if (!navigation) {
      return;
    }

    let scrollPosition = 0
    body.addEventListener('scroll', () => {
      if (scrollPosition < body.scrollTop) {
        if (body.scrollTop > 48) {
          navigation.classList.add('hidden')
        }
      } else {
        navigation.classList.remove('hidden')
      }
      scrollPosition = body.scrollTop;
    });
  });
};
