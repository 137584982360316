export default () => {
  document.addEventListener("turbolinks:load", () => {
    const backBtns = document.querySelectorAll('.user a.btn-back-link') || []

    // If there is no history, back button change to home button
    for (const backBtn of backBtns) {
      if (backBtn.href == 'javascript:history.back()') {
        // redirect back to top page if there is no histroy
        backBtn.href = '/'
      } else {
        // replace spesific url to js to avoid loop
        backBtn.href = 'javascript:history.back()'
      }
    }
  })
}
