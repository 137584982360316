export default () => {
  document.addEventListener("turbolinks:load", () => {
    const likes = document.querySelectorAll('.user .like-icon')

    if (!likes) {
      return;
    }

    const axios = require('axios');
    const like_image = '/images/icons/like_button_empty.svg'
    const liked_image = '/images/icons/like_button_full.svg'

    likes.forEach((like) => {
      like.addEventListener('click', () => {
        const type = like.dataset.type
        const id = like.dataset.id
        const action = like.dataset.action

        if (action != 'remove' && action !== 'add') {
          return
        }

        if (action == 'remove') {
          like.dataset.action = 'add'
          like.querySelector('img').src = like_image
        } else if (action == 'add') {
          like.dataset.action = 'remove'
          like.querySelector('img').src = liked_image
        }

        axios.post('/likes', { id: id, type: type, required_action: action })
        .then(response => {
        })
        .catch(error => {
          console.log(error)
        });
      })
    })
  })
}
