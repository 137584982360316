<template>
  <div class="field text-center mt-48">
    <div class="mb-16 width-400 m-auto text-left pl-8">
      <label for="post_product_id" class="label-required">{{ attributeName }}</label>
    </div>

    <div class="field width-400 mt-24 m-auto">
      <input type="text" class="input" placeholder="Please enter a search keyword" v-model="serchKeywrod">
    </div>

    <div class="select width-400">
      <select name="post[product_id]" id="post_product_id" class="width-100p" v-model="selectedProduct">
        <option v-for="product in products" :value="product[0]">{{ product[1] }}</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    attributeName: String,
    defaultOptions: String,
    defaultSelectedProduct: String,
  },

  data() {
    return {
      allProducts: [],
      selectedProduct: '',
      serchKeywrod: '',
    }
  },

  computed: {
    products() {
      const regex = new RegExp(this.serchKeywrod, 'i');
      const items = this.allProducts.filter(product => product[1].match(regex));

      if(this.serchKeywrod != '' && items.length > 0) {
        this.selectedProduct = items[0][0];
      }

      if(this.serchKeywrod != '' && items.length == 0) {
        this.selectedProduct = null;
      }

      return items;
    },
  },

  mounted() {
    this.allProducts = JSON.parse(this.defaultOptions);

    if(this.defaultSelectedProduct != null && this.defaultSelectedProduct != '') {
      this.selectedProduct = this.defaultSelectedProduct;
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
