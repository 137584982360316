import Vue from 'vue/dist/vue.esm'

import AdminTagMultiSelect from '../components/admin_tag_multi_select.vue'
import AdminTagSingleSelect from '../components/admin_tag_single_select.vue'
import AdminOtherLinks from '../components/admin_other_links.vue'

export default () => {
  document.addEventListener("turbolinks:load", () => {
    if (document.getElementById('admin-user-form')) {
      const chat = new Vue({
        el: '#admin-user-form',
        components: { AdminTagMultiSelect, AdminTagSingleSelect, AdminOtherLinks }
      });

      // multi-select
      const selects = document.querySelectorAll('.multi-select-container')
      for (const select of selects) {
        const target = select.querySelector('.user-multi-tag-select')
        const observer = new ResizeObserver(_entries => {
          const height = target.clientHeight + 20
          select.style.height = height + "px"
        })
        observer.observe(target)
      }
    }
  });
};
