// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import '@fortawesome/fontawesome-free/js/all'

// Import JS modules using Vue.js
import SearchPage from 'user/search_page'
import SearchForm from 'user/search_form'
import AdminProductForm from 'admin/product_form'
import AdminPostForm from 'admin/post_form'
import AdminUserForm from 'admin/user_form'
import AdminReviewForm from 'admin/review_form'
import AdminImagePreview from 'admin/image_preview'
import AdminVideoPreview from 'admin/video_preview'
import UserUserForm from 'user/user_form'
import UserProductForm from 'user/product_form'
import UserPostForm from 'user/post_form'
import UserImagePreview from 'user/image_preview'
import UserList from 'user/user_list'
import UserVideoPreview from 'user/video_preview'
import UserTagScroll from 'user/tag_scroll'
SearchPage()
SearchForm()
AdminProductForm()
AdminPostForm()
AdminUserForm()
AdminReviewForm()
AdminImagePreview()
AdminVideoPreview()
UserUserForm()
UserProductForm()
UserPostForm()
UserImagePreview()
UserList()
UserVideoPreview()
UserTagScroll()

// Import them after loading Vue module due to conflict
import TranslateText from 'admin/translate_text'
TranslateText()

import ToggleNavigation from 'user/toggle_navigation'
import ToggleTags from 'user/toggle_tags'
import ToggleSpecification from 'user/toggle_specification'
import ToggleLanguage from 'user/toggle_language'
import ScrollTags from 'user/scroll_tags'
import ScrollTop from 'user/scroll_top'
import ToggleUserTabs from 'user/toggle_user_tabs'
import ToggleProductTabs from 'user/toggle_product_tabs'
import TogglePostType from 'admin/toggle_post_type'
import LikeButton from 'user/like_button'
import CollectionButton from 'user/collection_button'
import RandomUserName from 'user/random_user_name'
import BackButton from 'user/back_button'
ToggleNavigation()
ToggleTags()
ToggleSpecification()
ToggleLanguage()
ScrollTags()
ScrollTop()
ToggleUserTabs()
ToggleProductTabs()
TogglePostType()
LikeButton()
CollectionButton()
RandomUserName()
BackButton()
import SeeMore from 'user/see_more'
SeeMore()

import UserUserPage from 'user/user_page'
UserUserPage()

// Import JS modules
import Flash from 'common/flash'
Flash()
import Modal from 'common/modal'
Modal()
