import Vue from 'vue/dist/vue.esm'

import UserTagMultiSelect from '../components/user_tag_multi_select.vue'
import UserTagSingleSelect from '../components/user_tag_single_select.vue'

export default () => {
  document.addEventListener("turbolinks:load", () => {
    if (document.getElementById('user-post-form')) {
      const form = new Vue({
        el: '#user-post-form',
        components: { UserTagMultiSelect, UserTagSingleSelect },
      });

      // set disabled for image post
      const articleCheck = document.getElementById('article')

      const toggleInputElement = function(isChecked) {
          if (isChecked) {
            document.getElementById('post_input_reference_title').disabled = false
            document.getElementById('post_input_reference_url').disabled = false
          } else {
            document.getElementById('post_input_reference_title').disabled = true
            document.getElementById('post_input_reference_url').disabled = true
          }
      }

      if (articleCheck) {
        articleCheck.addEventListener('change', () => {
          toggleInputElement(articleCheck.checked)
        })

        toggleInputElement(articleCheck.checked)
      }

      // Enable button after check
      const guidelineCheck = document.getElementById('guideline')

      if (guidelineCheck) {
        const disabledText = document.querySelector('.form-submit').value
        const enabledText = document.querySelector('.submit-button-text').innerText

        const toggleSubmitElement = function(isChecked, enabledText, disabledText) {
          const submitBtn = document.querySelector('.form-submit')
          if(isChecked) {
            submitBtn.classList.remove('form-submit-disable')
            submitBtn.value = enabledText
            submitBtn.disabled = false
          } else {
            submitBtn.classList.add('form-submit-disable')
            submitBtn.value = disabledText
            submitBtn.disabled = true
          }
        }

        guidelineCheck.addEventListener('change', () => {
          toggleSubmitElement(guidelineCheck.checked, enabledText, disabledText)
        })
        toggleSubmitElement(guidelineCheck.checked, enabledText, disabledText)
      }


      // Set delete post
      const deleteLink = document.querySelector('.delete-note a')
      if (deleteLink) {
        deleteLink.addEventListener('click', () => {
          const targetModal = document.querySelector('.modal-delete-post')
          if (targetModal) {
            targetModal.classList.remove('hidden')
          }
        })
      }

      // Set recommendation modal
      const recommendationLinks = document.querySelectorAll('.post-image-note') || []
      for (const recommendationLink of recommendationLinks) {
        recommendationLink.addEventListener("click", () => {
          const target = document.querySelector(".modal-picture-recommendation")
          if (target) {
            target.classList.remove('hidden')
          }
        })
      }


      // Set leave page modal
      const target = document.querySelector(".modal-leave-page")
      if (!target) {
        return
      }
      history.pushState(null, null, location.href);
      window.addEventListener('popstate', function(event) {
        if (document.getElementById('user-post-form')) {
          const targetModal = document.querySelector('.modal-leave-page')
          targetModal.classList.remove('hidden')
          history.go(1)
        }
      })

      const blockLinkParentClasses = [
        '.header',
        '.footer',
        '.modal-new-container',
        '.modal-options',
        '.bottom-navigation-container',
        '.upper'
      ]
      for (const blockParentClass of blockLinkParentClasses) {
        const links = document.querySelectorAll(`${blockParentClass} a`) || []
        for (const link of links) {
          link.addEventListener('click', (event) => {
            const targetModal = document.querySelector('.modal-leave-page')
            const targetLink = targetModal.querySelector('.btn-leave')

            if (link.href) {
              targetLink.href = link.href
            }

            targetModal.classList.remove('hidden')
            event.preventDefault()
          })
        }
      }

      const searchForm = document.getElementById('search-form')
      if (searchForm) {
        searchForm.addEventListener('submit', (event) => {
          const q = document.getElementById('search-query').value
          const requestUrl = `/search?q=${q}`
          event.preventDefault()

          const targetModal = document.querySelector('.modal-leave-page')
          const targetLink = targetModal.querySelector('.btn-leave')
          targetLink.href = requestUrl
          targetModal.classList.remove('hidden')
        })
      }
    }
  });
};
