<template>
  <div class="admin-other-links">
    <div v-for="(item, i) in values" class="field width-100p mb-24 m-auto">
      <div class="mt-16 text-left pl-8">
        <label>Title</label>
      </div>
      <input type="text" class="input" v-model="values[i].title" />

      <div class="mt-8 text-left pl-8">
        <label>URL</label>
      </div>
      <input type="text" class="input" v-model="values[i].url" />
    </div>

    <div class="width-100p m-auto text-right mt-16">
      <span class="button is-small" @click="addItem">Add</span>
    </div>
    <input type="hidden" :value="inputtedValues" :name="fieldName" />
  </div>
</template>

<script>
export default {
  props: {
    attributeName: String,
    fieldName: String,
    valuesJson: String,
  },

  data() {
    return {
      values: [{ 'title': '', 'url': '' }],
    }
  },

  computed: {
    inputtedValues() {
      return JSON.stringify(this.values.filter(value => value.title && value.url))
    },
  },

  methods: {
    addItem() {
      this.values.push({ 'title': '', 'url': '' });
    },
  },

  mounted() {
    try {
      if (JSON.parse(this.valuesJson).length > 0) {
        this.values = JSON.parse(this.valuesJson)
      }
    } catch (_error) {
      // console.log(error);
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
