export default () => {
  document.addEventListener("turbolinks:load", () => {
    const select = document.querySelector('#post-post-type input[type="hidden"]');

    if (!select) {
      return;
    }

    function toggleVisibilityByPostType(selectedValue) {
      const postTypes = ['post_image', 'post_article', 'post_video']

      for (const postType of postTypes) {
        if (postType === selectedValue) {
          continue;
        }

        document.querySelectorAll(`.${postType}-container`).forEach(element => {
          element.classList.add('hidden')
        })
      }

      // Remove hidden class after added it to other post type elements
      document.querySelectorAll(`.${selectedValue}-container`).forEach(element => {
        element.classList.remove('hidden')
      })
    }

    function callback(mutationList) {
      mutationList.forEach((mutation) => {
        toggleVisibilityByPostType(mutation.target.value)
      })
    }

    const observer = new MutationObserver(callback)
    const config = {
      childList: true,
      attributes: true,
      subtree: true
    }
    observer.observe(select, config)
  });
};
