<template>
  <div class='user-single-tag-select' :class="containerClass">
    <template v-if="isObject === 'true'">
      <multiselect
        v-model="value"
        track-by="id"
        label="name"
        :options="options"
        :multiple="false"
        :searchable="true"
        :taggable="itemAddable"
        @select="selectTag"
        @tag="addTag">
      </multiselect>
      <input type="hidden" :name="fieldName" :value="value.id">
    </template>

    <template v-else>
      <multiselect
        v-model="value"
        :options="options"
        :multiple="false"
        :searchable="true"
        :taggable="itemAddable"
        @select="selectTag"
        @tag="addTag">
      </multiselect>
      <input type="hidden" :name="fieldName" :value="value">
    </template>

  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  props: {
    optionsJson: String,
    defaultValue: String,
    fieldName: String,
    addable: String,
    containerClass: String,
    isObject: String,
  },

  computed: {
    itemAddable() {
      return this.addable === "true"
    }
  },

  components: {
    Multiselect
  },

  data () {
    return {
      options: [],
      value: ''
    }
  },

  methods: {
    addTag (newTag) {
      this.options.push(newTag)
      this.value = newTag
    },

    selectTag (selectedTag) {
      const elem = document.querySelector(`.${this.containerClass} .multiselect__tags .multiselect__input`)
      elem.classList.add('tag-style')
    },
  },

  mounted() {
    this.options = JSON.parse(this.optionsJson)
    if (this.isObject === 'true') {
        for (const option of this.options) {
          if (option.id == this.defaultValue) {
            this.value = option
          }
        }
    } else {
      if (this.options.includes(this.defaultValue)) {
        this.value = this.defaultValue
      }
    }
  },
}

</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.user-single-tag-select {
  .multiselect__tags {
    .multiselect__input.tag-style {
      // background: #41b883;
      // width: auto;
      // padding: 2px 10px;
      // color: #fff;
    }
  }
}
</style>
