export default () => {
  document.addEventListener("turbolinks:load", () => {
    const tagsWrapper = document.querySelector('.header .tags-center')
    const tagsInner = document.querySelector('.header .tags-inner')

    if (tagsWrapper && tagsInner) {
      // Set initial scroll position
      const tagsWith = tagsInner.clientWidth / 3
      tagsWrapper.scrollLeft = tagsWith

      tagsWrapper.addEventListener('scroll', function() {
        const leftPosttion = tagsWrapper.scrollLeft
        const rightPosttion = tagsInner.offsetWidth - tagsWrapper.offsetWidth - tagsWrapper.scrollLeft

        if (leftPosttion < tagsWith) {
          const firstTagSet = tagsInner.firstElementChild;
          const clonedFirstTagSet = firstTagSet.cloneNode(true);
          tagsInner.insertBefore(clonedFirstTagSet, firstTagSet);
          tagsWrapper.scrollLeft = tagsWrapper.scrollLeft + tagsWith
        } else if (rightPosttion < tagsWith) {
          const firstTagSet = tagsInner.firstElementChild;
          const clonedFirstTagSet = firstTagSet.cloneNode(true);
          tagsInner.appendChild(clonedFirstTagSet)
        }
      })
    }
  });
};
