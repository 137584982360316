export default () => {
  document.addEventListener("turbolinks:load", () => {
    if (document.querySelector('.user.users-controller.show-action')) {
      const axios = require('axios');

      const likes = document.querySelectorAll('.user-like-item') || []
      likes.forEach((like) => {
        const btn = like.querySelector('.user-like-cancel-icon')
        btn.addEventListener("click", (event) => {
          const type = btn.dataset.type
          const id = btn.dataset.id

          axios.post('/likes', { id: id, type: type, required_action: 'remove' })
          .then(response => {
          })
          .catch(error => {
            console.log(error)
          });

          event.preventDefault();
          event.stopPropagation();
          like.parentNode.remove()
        })
      })

      const collections = document.querySelectorAll('.user-collection-item') || []
      collections.forEach((collection) => {
        const btn = collection.querySelector('.user-collection-cancel-icon')
        btn.addEventListener("click", (event) => {
          const type = btn.dataset.type
          const id = btn.dataset.id

          axios.post('/collections', { id: id, type: type, required_action: 'remove' })
          .then(response => {
          })
          .catch(error => {
            console.log(error)
          });

          event.preventDefault();
          event.stopPropagation();
          collection.parentNode.remove()
        })
      })
    }
  });
};
