export default () => {
  document.addEventListener("turbolinks:load", () => {
    const input = document.getElementById('user_create_user_account_form_name')

    if (input) {
      const target = document.getElementById('user_create_user_account_form_user_name')
      let userNameInputted = false

      input.addEventListener("change", () => {
        if (!userNameInputted) {
          const randomNumber = Math.floor(Math.random() * 100000000)
          const prefix = ("0000000" + randomNumber).slice(-7)

          target.value = `${input.value}${prefix}`
        }
      })

      target.addEventListener("keyup", () => {
        userNameInputted = true
      })
    }
  })
}
