import Vue from 'vue/dist/vue.esm'

import UserTagMultiSelect from '../components/user_tag_multi_select.vue'
import UserTagSingleSelect from '../components/user_tag_single_select.vue'
import UserOtherLinks from '../components/user_other_links.vue'

export default () => {
  document.addEventListener("turbolinks:load", () => {
    if (document.getElementById('user-product-form')) {
      const form = new Vue({
        el: '#user-product-form',
        components: { UserTagMultiSelect, UserTagSingleSelect, UserOtherLinks },
      });


      // Set realtime name and nickname reflection
      const nameInput = document.getElementById('product_input_name')
      const nicknameInput = document.getElementById('product_input_nickname')

      if (nameInput) {
        nameInput.addEventListener("input", () => {
          const target = document.querySelector('.product-name-value')
          target.innerText = nameInput.value
        })
      }

      if (nicknameInput) {
        nicknameInput.addEventListener("input", () => {
          const target = document.querySelector('.product-tag-value')
          target.innerText = nicknameInput.value
        })
      }

      // Set recommendation modal
      const recommendationLink = document.querySelector('.product-image-note')
      if (recommendationLink) {
        recommendationLink.addEventListener("click", () => {
          const target = document.querySelector(".modal-picture-recommendation")
          if (target) {
            target.classList.remove('hidden')
          }
        })
      }


      // Set leave page modal
      const target = document.querySelector(".modal-leave-page")
      if (!target) {
        return
      }
      history.pushState(null, null, location.href);
      window.addEventListener('popstate', function(event) {
        if (document.getElementById('user-product-form')) {
          const targetModal = document.querySelector('.modal-leave-page')
          targetModal.classList.remove('hidden')
          history.go(1)
        }
      })

      const blockLinkParentClasses = [
        '.header',
        '.footer',
        '.modal-new-container',
        '.modal-options',
        '.bottom-navigation-container',
        '.upper'
      ]
      for (const blockParentClass of blockLinkParentClasses) {
        const links = document.querySelectorAll(`${blockParentClass} a`) || []
        for (const link of links) {
          link.addEventListener('click', (event) => {
            const targetModal = document.querySelector('.modal-leave-page')
            const targetLink = targetModal.querySelector('.btn-leave')

            if (link.href) {
              targetLink.href = link.href
            }

            targetModal.classList.remove('hidden')
            event.preventDefault()
          })
        }
      }

      const searchForm = document.getElementById('search-form')
      if (searchForm) {
        searchForm.addEventListener('submit', (event) => {
          const q = document.getElementById('search-query').value
          const requestUrl = `/search?q=${q}`
          event.preventDefault()

          const targetModal = document.querySelector('.modal-leave-page')
          const targetLink = targetModal.querySelector('.btn-leave')
          targetLink.href = requestUrl
          targetModal.classList.remove('hidden')
        })
      }
    }
  });
};
