import Vue from 'vue/dist/vue.esm'

import AdminTagMultiSelect from '../components/admin_tag_multi_select.vue'
import AdminTagMultiReview from '../components/admin_tag_multi_review.vue'
import AdminTagSingleSelect from '../components/admin_tag_single_select.vue'
import AdminTagSingleReview from '../components/admin_tag_single_review.vue'
import AdminOtherLinks from '../components/admin_other_links.vue'

export default () => {
  document.addEventListener("turbolinks:load", () => {
    if (document.getElementById('admin-review-form')) {
      const chat = new Vue({
        el: '#admin-review-form',
        components: { AdminTagMultiSelect, AdminTagMultiReview, AdminTagSingleSelect, AdminTagSingleReview, AdminOtherLinks }
      });

      // Check if all review is completed before submitting
      const submit = document.getElementById('review-submit')
      submit.addEventListener("click", (event) => {
        const select = document.querySelector('#admin-review-form .status-select input[type="hidden"]')

        if (select && select.value == 'approved') {
          const items = document.querySelectorAll('.review-item')
          if (items.length > 0) {
            event.preventDefault()
            window.alert('Please complete review before submitting')
          }
        }
      })


      // Hide rejected reason
      const select = document.querySelector('#admin-review-form .status-select input[type="hidden"]');

      if (select) {
        function toggleVisibilityByStatus(selectedValue) {
          const target = document.querySelector('#admin-review-form .review-reject-reason-container')

          if (selectedValue == 'rejected') {
            target.classList.remove('hidden')
          } else if (selectedValue == 'approved') {
            target.classList.add('hidden')
          }
        }

        function callback(mutationList) {
          mutationList.forEach((mutation) => {
            toggleVisibilityByStatus(mutation.target.value)
          })
        }

        const observer = new MutationObserver(callback)
        const config = {
          childList: true,
          attributes: true,
          subtree: true
        }
        observer.observe(select, config)
      }
    }
  });
};
