export default () => {
  document.addEventListener("turbolinks:load", () => {

    // Set close button
    const modals = document.querySelectorAll('.user-modal') || [];
    for (const modal of modals) {
      const closeBtns = modal.querySelectorAll('.user-modal .btn-close') || [];

      for (const closeBtn of closeBtns) {
        closeBtn.addEventListener('click', () => {
          modal.classList.add('hidden')
        });
      }
    }

    // Open modal from flash
    const modalFlash = document.getElementById('modal-control')
    if (modalFlash) {
      const modalName = modalFlash.dataset.modal
      const target = document.querySelector(`.${modalName}`)
      if (target) {
        target.classList.remove('hidden')
      }
    }

    // Set modal links
    const modalLinks = document.querySelectorAll('.modal-link') || [];
    for (const link of modalLinks) {
      link.addEventListener('click', (event) => {
        const modalName = link.dataset.modalName

        const target = document.querySelector(`.${modalName}`)
        if (target) {
          closeAllModals()

          if (modalName == 'modal-signin' || modalName == 'modal-signup') {
            const body = document.querySelector('body');
            body.scrollTo({
              top: 0,
              behavior: 'smooth'
            });
          }

          target.classList.remove('hidden')
        }

        // Call customized method
        switch (modalName) {
          case 'modal-signin':
            openSignInModal()
            break;
          case 'modal-signup':
            openSignUpModal()
            break;
          case 'modal-likes':
            openLikeModal(link)
            break;
          case 'modal-collections':
            openCollectionModal(link)
            break;
          case 'modal-language':
            openLanguageModal()
            break;
        }
        event.preventDefault()
      });
    }

    // Set language modal
    const languagePreference = document.getElementById('language-preference')
    if (languagePreference) {
      const currentLanguage = document.querySelector('.current-primary-language').innerText

      languagePreference.addEventListener('change', (event) => {
        // Don't open the modal if the language is not changed
        if (languagePreference.value == currentLanguage) {
          return true
        }

        const target = document.querySelector('.modal-language')
        if (target) {
          // Set new language from select
          target.querySelector('#user_primary_language').value = languagePreference.value

          closeAllModals()
          target.classList.remove('hidden')
        }
      })
    }

    function openSignInModal() {
      const form = document.getElementById('user-signin-form')

      if (form) {
        form.addEventListener('ajax:success', (evt) => {
          const data = evt.detail[0]
          Turbolinks.visit(data.location)
        })

        form.addEventListener('ajax:error', (evt) => {
          form.querySelector('.error').classList.remove('hidden')
        })
      }
    }

    function openSignUpModal() {
      const form = document.getElementById('user-signup-form')

      if (form) {
        form.addEventListener('ajax:success', (evt) => {
          const data = evt.detail[0]
          Turbolinks.visit(data.location)
        })

        form.addEventListener('ajax:error', (evt) => {
          // replace error message using response
          const messages = evt.detail[0].messages
          if (messages && messages.length > 0) {
            form.querySelector('.error').innerText = messages.join("\n")
          }

          form.querySelector('.error').classList.remove('hidden')
        })
      }
    }

    function openLikeModal(link) {
      const id = link.dataset.contentId
      const type = link.dataset.contentType

      const target = document.querySelector('.user-list-data.hidden.user-list-like-modal')
      if (target) {
        target.querySelector('.user-list-target-id').innerText = id
        target.querySelector('.user-list-target-type').innerText = type
      }
    }

    function openCollectionModal(link) {
      const id = link.dataset.contentId
      const type = link.dataset.contentType

      const target = document.querySelector('.user-list-data.hidden.user-list-collection-modal')
      if (target) {
        target.querySelector('.user-list-target-id').innerText = id
        target.querySelector('.user-list-target-type').innerText = type
      }
    }

    function closeAllModals() {
      const modals = document.querySelectorAll('.user-modal') || [];
      for (const modal of modals) {
        modal.classList.add('hidden')
      }
    }
  });
};
