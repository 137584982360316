import Vue from 'vue/dist/vue.esm'
import SearchForm from '../components/search_form.vue'

import Buefy from 'buefy'
import 'buefy/dist/buefy.css'

export default () => {
  document.addEventListener("turbolinks:load", () => {
    if (document.getElementById('search-form')) {
      Vue.use(Buefy)
      const elem = new Vue({
        el: '#search-form',
        components: { SearchForm }
      });
    }
  });
};
