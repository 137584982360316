export default () => {
  document.addEventListener("turbolinks:load", () => {
    const image = document.querySelector('.user input.upload-image');

    if (image) {
      const preview = document.getElementById('image-preview');

      image.addEventListener('change', () => {
        const file = image.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
          preview.style.backgroundImage = 'url(' + e.target.result + ')';

          const boforeItems = preview.querySelectorAll('.preview-before')
          for (const boforeItem of boforeItems) {
            boforeItem.classList.add('hidden')
          }

          const afterItems = preview.parentNode.querySelectorAll('.preview-after')
          for (const afterItem of afterItems) {
            afterItem.classList.remove('hidden')
          }
        }
        reader.readAsDataURL(file);
      });
    }
  });
};
