<template>
  <div class='user-single-tag-review' :class="containerClass">
    <div v-if="hasCandidate">
      <div class="text-left">
        <span>{{ objectName }}</span>
      </div>

      <div class="mt-8 flex-row">
        <span class="input width-80p review-item">{{ candidate }}</span>
        <button class="delete ml-8 mt-8" @click="removeCandidate"></button>
      </div>

      <div class="text-right mt-8 mb-24">
        <a class="button" :href="createUrl" target='_blank'>Create new</a>
      </div>
    </div>

    <multiselect
      v-model="value"
      :options="options"
      :multiple="false"
      :searchable="true"
      @open="fetchOptions"
      @select="selectTag">
    </multiselect>
    <input type="hidden" :name="fieldName" :value="value">
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
const axios = require('axios');

export default {
  props: {
    fetchUrl: String,
    createUrl: String,
    objectName: String,
    optionsJson: String,
    defaultValue: String,
    fieldName: String,
    containerClass: String,
  },

  computed: {
    hasCandidate() {
      return this.candidate !== ''
    }
  },

  components: {
    Multiselect
  },

  data () {
    return {
      candidate: '',
      options: [],
      value: '',
    }
  },

  methods: {
    removeCandidate () {
      this.candidate = ''
    },
    selectTag (selectedTag) {
      const elem = document.querySelector(`.${this.containerClass} .multiselect__tags .multiselect__input`)
      elem.classList.add('tag-style')
    },
    fetchOptions () {
      axios.get(this.fetchUrl, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }})
        .then(response => {
          console.log(response)
            response.data.data.forEach(content => {
              if (!this.options.includes(content)) {
                this.options.push(content)
              }
            })
        })
        .catch(error => {
          console.log(error);
        });
    },
  },

  mounted() {
    this.options = JSON.parse(this.optionsJson)
    if (this.isObject === 'true') {
        for (const option of this.options) {
          if (option.id == this.defaultValue) {
            this.value = option
          }
        }
    } else {
      if (this.options.includes(this.defaultValue)) {
        this.value = this.defaultValue
      } else {
        this.candidate = this.defaultValue
      }
    }
  },
}

</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.user-single-tag-select {
  .multiselect__tags {
    .multiselect__input.tag-style {
      // background: #41b883;
      // width: auto;
      // padding: 2px 10px;
      // color: #fff;
    }
  }
}
</style>
