<template>
  <div class="user-other-links">
    <template v-if="isOpen">
      <div class='label'>
        <label>{{ attributeName }}</label>
      </div>

      <div class="mt-16 text-left pl-8">
        <label>Title</label>
      </div>
      <input type="text" class="input" v-model="values[0].title" :placeholder="placeholderTitle"/>

      <div class="mt-8 text-left pl-8">
        <label>URL</label>
      </div>
      <input type="text" class="input" v-model="values[0].url" :placeholder="placeholderUrl"/>
    </template>

    <template v-else>
      <div class="form-item-link" @click="openLinks">
        <div class="form-item-link-icon">
          <img src="/images/plus_icon.png">
        </div>
        <div class="form-item-link-text">
          <span>{{ addButtonText }}</span>
        </div>
      </div>
    </template>

    <input type="hidden" :value="inputtedValues" :name="fieldName" />
  </div>
</template>

<script>
export default {
  props: {
    attributeName: String,
    addButtonText: String,
    fieldName: String,
    valuesJson: String,
    placeholderUrl: String,
    placeholderTitle: String,
  },

  data() {
    return {
      values: [{ 'title': '', 'url': '' }],
      addClicked: false
    }
  },

  computed: {
    isOpen() {
      return this.addClicked || this.values[0].title || this.values[0].url
    },
    inputtedValues() {
      return JSON.stringify(this.values.filter(value => value.title && value.url))
    },
  },

  methods: {
    openLinks() {
      this.addClicked = true
    },
  },

  mounted() {
    try {
      if (JSON.parse(this.valuesJson).length > 0) {
        this.values = JSON.parse(this.valuesJson)

        if (this.values[0].title || this.values[0].url) {
          this.addClicked = true
        }
      }
    } catch (_error) {
      // console.log(error);
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
