<template>
  <div class='user-single-tag-select' :class="containerClass">
    <multiselect
      v-model="value"
      :options="options"
      :multiple="false"
      :searchable="true"
      :taggable="itemAddable"
      :placeholder="placeholder"
      :selectLabel='selectLabel'
      :deselectLabel='deselectLabel'
      :selectedLabel='selectedLabel'
      :tagPlaceholder='createLabel'
      @select="selectTag"
      @tag="addTag">
    </multiselect>

    <input type="hidden" :name="fieldName" :value="value">
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  props: {
    optionsJson: String,
    defaultValue: String,
    fieldName: String,
    addable: String,
    containerClass: String,
    placeholder: String,
    selectLabel: String,
    deselectLabel: String,
    selectedLabel: String,
    createLabel: String,
  },

  computed: {
    itemAddable() {
      return this.addable === "true"
    }
  },

  components: {
    Multiselect
  },

  data () {
    return {
      options: [],
      value: ''
    }
  },

  methods: {
    addTag (newTag) {
      this.options.push(newTag)
      this.value = newTag
    },

    selectTag (selectedTag) {
      const elem = document.querySelector(`.${this.containerClass} .multiselect__tags .multiselect__input`)
      elem.classList.add('tag-style')
    },
  },

  mounted() {
    this.options = JSON.parse(this.optionsJson)
    if (this.options.includes(this.defaultValue)) {
      this.value = this.defaultValue
    }
  },
}

</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.user-single-tag-select {
  .multiselect__tags {
    .multiselect__input.tag-style {
      // background: #41b883;
      // width: auto;
      // padding: 2px 10px;
      // color: #fff;
    }
  }
}
</style>
