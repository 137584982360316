export default () => {
  document.addEventListener("turbolinks:load", () => {
    const axios = require('axios');

    const buttons = document.querySelectorAll('.btn-translate') || [];
    buttons.forEach((button) => {
      button.addEventListener('click', () => {
        const translateType = Array.from(button.classList).find(name => name.startsWith('translate-to'));
        const otherClass = translateType === 'translate-to-ja' ? '.translate-to-en' : '.translate-to-ja';
        const originalInput = button.parentNode.parentNode.querySelector('.translate-text');
        const targetInput = button.parentNode.parentNode.parentNode.querySelector(otherClass).parentNode.parentNode.querySelector('.translate-text');
        const targetLang = translateType === 'translate-to-ja' ? 'ja' : 'en';

        axios.post('/admin/translate', { text: originalInput.value, lang: targetLang })
          .then(response => {
            const translatedText = response.data.text;
            targetInput.value = translatedText;
          })
          .catch(error => {
            console.log('ERROR: fetch sub_categories')
          });
      });
    });
  });
};
