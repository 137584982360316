export default () => {
  document.addEventListener("turbolinks:load", () => {
    const button = document.querySelector('.user.products-controller.show-action .link-outer');

    if (!button) {
      return;
    }

    button.addEventListener('click', () => {
      const target = document.querySelector('.user.products-controller.show-action .specification-lower');
      button.classList.add('hidden');
      target.classList.remove('hidden');
    });
  });
};
