<template>
  <div class='user-multi-tag-select'>
    <multiselect
      v-model="values"
      :options="options"
      :multiple="true"
      :searchable="true"
      :taggable="itemAddable"
      :placeholder="placeholder"
      :selectLabel='selectLabel'
      :deselectLabel='deselectLabel'
      :selectedLabel='selectedLabel'
      :tagPlaceholder='createLabel'
      @tag="addTag">
    </multiselect>

    <input type="hidden" :name="fieldName" :value="JSON.stringify(values)">
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  props: {
    optionsJson: String,
    valuesJson: String,
    fieldName: String,
    addable: String,
    placeholder: String,
    selectLabel: String,
    deselectLabel: String,
    selectedLabel: String,
    createLabel: String,
  },

  computed: {
    itemAddable() {
      return this.addable === "true"
    },
  },

  components: {
    Multiselect
  },

  data () {
    return {
      options: [],
      values: []
    }
  },

  methods: {
    addTag (newTag) {
      this.options.push(newTag)
      this.values.push(newTag)
    }
  },

  mounted() {
    if (this.options) {
      this.options = JSON.parse(this.optionsJson)
    }
    if (this.valuesJson) {
      this.values = JSON.parse(this.valuesJson)
    }
  },
}

</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
</style>
