export default () => {
  document.addEventListener("turbolinks:load", () => {
    const element = document.getElementById('go-top');
    const body = document.querySelector('body');

    if (!element) {
      return;
    }

    body.addEventListener('scroll', () => {
      if (body.scrollTop < 200) {
        element.classList.add('hidden');
      } else {
        element.classList.remove('hidden');
      }
    });

    element.addEventListener('click', () => {
      body.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    });
  });
};
