<template>
  <div class="search-page-container">
    <!-- filter -->
    <div class="filter">
      <div class="filter-upper">
        <div class="filter-count">{{ resultText }}</div>
        <div class="filter-icon">
          <img src="/images/filter_icon.png" @click="toggleFilterFolding()">
        </div>
      </div>

      <div class="filter-lower" v-if="filterOpened">

        <!-- by sort -->
        <div class="filter-item-outer">
          <div class="filter-item-outer-header" @click="toggleFilterOuterStatus('sort_by_date')">
            <div class="filter-item-outer-title">{{ titleByDate }}</div>
            <div class="filter-item-image">
              <img src="/images/fold_icon.svg" v-if="filterOuterStatus['sort_by_date'] != 'close'">
              <img src="/images/unfold_icon.svg" v-else>
            </div>
          </div>
        </div>
        <template v-if="filterOuterStatus['sort_by_date'] == 'open'">
          <div class="filter-item">
            <div class="filter-item-elements filter-by-sort">
              <template v-if="sortByDateElement.key != null">
                <div class="filter-item-element" v-for="(item, index) in sortByDateElement.data">
                  <div class="filter-item-checkbox">
                    <input type="checkbox" :id="sortByDateElement.key + item.id" :value="item.id" v-model="filterItems[sortByDateElement.key]">
                    <label :for="sortByDateElement.key + item.id">{{ item.name }}</label>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </template>


        <!-- by content -->
        <div class="filter-item-outer">
          <div class="filter-item-outer-header" @click="toggleFilterOuterStatus('filter_by_content')">
            <div class="filter-item-outer-title">{{ titleByContent }}</div>
            <div class="filter-item-image">
              <img src="/images/fold_icon.svg" v-if="filterOuterStatus['filter_by_content'] != 'close'">
              <img src="/images/unfold_icon.svg" v-else>
            </div>
          </div>
        </div>
        <template v-if="filterOuterStatus['filter_by_content'] == 'open'">
          <div class="filter-item">
            <div class="filter-item-elements filter-by-content">
                <div class="filter-item-element" v-for="(item, index) in fileterByContentElement.data" v-if="fileterByContentElement.key != null">
                  <div class="filter-item-image">
                    <img :src="filterByContentImage(item.id)" @click="toggleContentTypeSelection(item.id)">
                  </div>
                  <div class="filter-item-label">
                    <span>{{ item.name }}</span>
                  </div>
                </div>
            </div>
          </div>
        </template>


        <div class="filter-item-outer">
          <div class="filter-item-outer-header" @click="toggleFilterOuterStatus('filter_by_type_of_robot')">
            <div class="filter-item-outer-title">{{ titleByType }}</div>
            <div class="filter-item-image">
              <img src="/images/fold_icon.svg" v-if="filterOuterStatus['filter_by_type_of_robot'] != 'close'">
              <img src="/images/unfold_icon.svg" v-else>
            </div>
          </div>
        </div>

        <template v-if="filterOuterStatus['filter_by_type_of_robot'] == 'open'">
          <div class="filter-item" v-for="element in filterElements" v-if="element.general == true">
            <div class="filter-item-header"  @click="toggleFilterStatus(element.key, 'open')">
              <div class="filter-item-image">
                <img src="/images/fold_icon.svg" v-if="filterStatus[element.key] != 'close'">
                <img src="/images/unfold_icon.svg" v-else>
              </div>
              <div class="filter-item-title">{{ element.title }}</div>
            </div>
            <div class="filter-item-elements filter-by-type" v-if="filterStatus[element.key] != 'close'">
              <div class="filter-item-element" v-for="(item, index) in element.data" v-if="isCheckVisible(element.key, index)">
                <div class="filter-item-checkbox">
                  <input type="checkbox" :id="element.key + item.id" :value="item.id" v-model="filterItems[element.key]">
                  <label :for="element.key + item.id">{{ item.name }}</label>
                </div>
                <div class="filter-item-tooltip" v-if="'hint' in item && item.hint != null && item.hint != ''">
                  <b-tooltip
                    :label="item.hint"
                    position="is-left"
                    type="is-light"
                    :triggers="['click', 'hover']"
                    :class="`tooltip-${element.key}-${item.id}`"
                    @open="openTooltip(element.key, item.id)"
                    @close="closeTooltip(element.key, item.id)">
                    <img class='question-hover hidden' :src="questionHoverIcon">
                    <img class='question-original' :src="questionIcon">
                  </b-tooltip>
                </div>
              </div>
            </div>
            <div class="link-outer" v-if="filterStatus[element.key] == 'partial' && element.data.length > 5">
              <div class="link-inner" @click="toggleFilterStatus(element.key, 'partial')">
                <div class="link-image">
                  <img src="/images/plus_icon.png">
                </div>
                <div class="link-text">{{ seeMoreText }}</div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <!-- contents -->
    <div class="loading" v-if="loading">
      <img src='/images/loading.gif'>
    </div>
    <div class="items">
      <div class="no-item" v-if="contents.length == 0 && !loading">
        {{ noItemText }}
      </div>

      <div class="item" v-for="item in contents">
        <search-page-item
          :is-mobile="isMobile"
          :is-signed-in="isSignedIn"
          :item="item"
          :see-detail-text="seeDetailText"
        >
        </search-page-item>
      </div>

      <!-- pagination -->
      <div class="paginate" v-if="hasPagination">
        <a class="paginate-link" :href="paginateUrl(1)">
          <span>&lt;</span>
        </a>
        <a class="paginate-link" v-if="pagePrevPrevPrev" :href="paginateUrl(pagePrevPrevPrev)">
          <span>{{ pagePrevPrevPrev }}</span>
        </a>
        <a class="paginate-link" v-if="pagePrevPrev" :href="paginateUrl(pagePrevPrev)">
          <span>{{ pagePrevPrev }}</span>
        </a>
        <a class="paginate-link" v-if="pagePrev" :href="paginateUrl(pagePrev)">
          <span>{{ pagePrev }}</span>
        </a>
        <a class="paginate-link current-page">
          <span @click="fetchResults(page)">{{ page }}</span>
        </a>
        <a class="paginate-link" v-if="pageNext" :href="paginateUrl(pageNext)">
          <span>{{ pageNext }}</span>
        </a>
        <a class="paginate-link" v-if="pageNextNext" :href="paginateUrl(pageNextNext)">
          <span>{{ pageNextNext }}</span>
        </a>
        <a class="paginate-link" v-if="pageNextNextNext" :href="paginateUrl(pageNextNextNext)">
          <span>{{ pageNextNextNext }}</span>
        </a>
        <a class="paginate-link" :href="paginateUrl(lastPage)">
          <span>&gt;</span>
        </a>
      </div>
    </div>

  </div>
</template>

<script>
const axios = require('axios');
import Vue from 'vue/dist/vue.esm'

import Buefy from 'buefy'
import 'buefy/dist/buefy.css'

import SearchPageItem from './search_page_item.vue'

Vue.use(Buefy)

export default {
  components: {
    SearchPageItem,
  },
  props: {
    defaultContents: String,
    defaultCount: String,
    filterElementsJson: String,
    filterTitleText: String,
    isMobile: String,
    isSignedIn: String,
    loadMoreText: String,
    noItemText: String,
    seeDetailText: String,
    seeMoreText: String,
    titleByDate: String,
    titleByContent: String,
    titleByType: String,
  },

  data() {
    return {
      questionIcon: "/images/icons/question_button.svg",
      questionHoverIcon: "/images/question_icon.png",
      contents: [],
      filterElements: [],
      filterItems: {},
      filterStatus: {},
      filterOuterStatus: { sort_by_date: 'open', filter_by_content: 'open', filter_by_type_of_robot: 'open' },
      initialized: false,
      loading: false,
      page: 1,
      resultCount: 0,
      isFilterOpened: false,
    }
  },

  computed: {
    // To avoid error to access data
    sortByDateElement() {
      if (this.filterElements.length == 0) {
        return { data: null, key: null }
      }
      return this.filterElements[0]
    },

    // To avoid error to access data
    fileterByContentElement() {
      if (this.filterElements.length < 1) {
        return { data: null, key: null }
      }
      return this.filterElements[1]
    },
    //
    // Return if filter is opened
    //
    filterOpened() {
      if(this.isMobile != 'true') {
        return true;
      }

      return this.isFilterOpened;
    },
    pageNext (){
      if (this.page + 1 <= this.lastPage) {
        return this.page + 1
      } else {
        false
      }
    },
    pageNextNext (){
      if (this.page + 2 <= this.lastPage) {
        return this.page + 2
      } else {
        false
      }
    },
    pageNextNextNext (){
      if (this.page + 3 <= this.lastPage) {
        return this.page + 3
      } else {
        false
      }
    },
    pagePrev (){
      if (this.page - 1 > 0) {
        return this.page - 1
      } else {
        false
      }
    },
    pagePrevPrev (){
      if (this.page - 2 > 0) {
        return this.page - 2
      } else {
        false
      }
    },
    pagePrevPrevPrev (){
      if (this.page - 3 > 0) {
        return this.page - 3
      } else {
        false
      }
    },
    hasPagination() {
      if (this.loading) {
        return false
      }

      return this.resultCount > 8
    },
    lastPage() {
      return Math.ceil(this.resultCount / 8)
    },

    //
    // Return if there are more results that are not displayed yet
    //
    hasMoreResults() {
      return this.contents.length < this.resultCount;
    },

    //
    // Return count of results in string
    //
    resultText() {
      if(this.filterTitleText != 'Filters') {
        return `${this.resultCount}件`;
      }

      if(this.resultCount > 1) {
        return `${this.resultCount} Results`;
      } else {
        return `${this.resultCount} Result`;
      }
    },
  },

  methods: {
    buildQuery(page) {
      const url = new URL(window.location.href);
      let params = url.searchParams
      params.set('page', page);

      Object.keys(this.filterItems).forEach(key => {
        if(this.filterItems[key].length === 0) {
          params.delete(key);
        } else {
          params.set(key, this.filterItems[key].join('+'));
        }
      })
      return params.toString()
    },

    closeTooltip(key, id) {
      const className = `tooltip-${key}-${id}`
      document.querySelector(`.${className} .question-hover`).classList.add('hidden')
      document.querySelector(`.${className} .question-original`).classList.remove('hidden')
    },

    //
    // Fetch more items
    //
    fetchResults(page) {
      if(this.loading) {
        return;
      }
      const contentsBackUp = this.contents

      this.contents = []
      this.loading = true;

      axios.get(this.fetchUrl(page), {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }})
        .then(response => {
          this.loading =false
          response.data.contents.forEach(content => {
            this.contents.push(content);
          });
          this.page = response.data.page
          this.resultCount = response.data.count
        })
        .catch(error => {
          this.loading =false;
          this.contents = contentsBackUp
          console.log(error);
          console.log('ERROR: fetch more results')
        });
    },

    //
    // Return url to fetch more item
    //
    fetchUrl(page) {
      const url = new URL(window.location.href);
      const paramsStr = this.buildQuery(page)
      return `${url.protocol}//${url.host}${url.pathname}?${paramsStr}`
    },

    filterByContentImage(id) {
      if (this.filterItems.filter_by_content.includes(id)) {
        return [
          "/images/icons/photo_filter_selected.svg",
          "/images/icons/article_filter_selected.svg",
          "/images/icons/video_filter_selected.svg",
          "/images/icons/robot_filter_selected.svg",
          "/images/icons/user_filter_selected.svg",
        ][id - 1]
      } else {
        return [
          "/images/icons/photo_filter_unselected.svg",
          "/images/icons/article_filter_unselected.svg",
          "/images/icons/video_filter_unselected.svg",
          "/images/icons/robot_filter_unselected.svg",
          "/images/icons/user_filter_unselected.svg",
        ][id - 1]
      }
    },

    //
    // Return image path depending on item type
    //
    iconImage(type) {
      if(type === 'post') {
        return '/images/icon/post_icon.svg';
      } else {
        return '/images/icon/product_icon.svg';
      }
    },

    //
    // Return if the check is visible
    //
    isCheckVisible(key, index) {
      return index < 5 || this.filterStatus[key] == 'open'
    },

    openTooltip(key, id) {
      const className = `tooltip-${key}-${id}`
      document.querySelector(`.${className} .question-hover`).classList.remove('hidden')
      document.querySelector(`.${className} .question-original`).classList.add('hidden')
    },

    //
    // Return url to paginate
    //
    paginateUrl(page) {
      const url = new URL(window.location.href);
      const params = this.buildQuery(page)
      return `${url.protocol}//${url.host}${url.pathname}?${params.toString()}`
    },

    toggleContentTypeSelection(id) {
      if (this.filterItems.filter_by_content.includes(id)) {
        this.filterItems.filter_by_content = this.filterItems.filter_by_content.filter(val => val != id)
      } else {
        this.filterItems.filter_by_content.push(id)
      }
    },

    //
    // Toggle filter status
    //   - open: show all items
    //   - partial: show 5 items for mobile
    //   - close: hide all items
    //
    toggleFilterStatus(key, type) {
      if(type == 'partial') {
        this.filterStatus[key] = 'open';
        return;
      }

      if(this.filterStatus[key] != 'close') {
        this.filterStatus[key] = 'close';
        return;
      }

      this.filterStatus[key] = 'partial';
    },

    //
    // Toggle filter outer status
    //   - open: show all items
    //   - close: hide all items
    //
    toggleFilterOuterStatus(key) {
      if(this.filterOuterStatus[key] == 'open') {
        this.filterOuterStatus[key] = 'close';
      } else {
        this.filterOuterStatus[key] = 'open';
      }
    },

    //
    // Toggle whole filter folding status
    //
    toggleFilterFolding() {
      this.isFilterOpened = !this.isFilterOpened;
    },

    //
    // unescape HTML character entities
    //
    unescapeString(string) {
      return new DOMParser().parseFromString(string,'text/html').querySelector('html').textContent;
    },
  },

  watch: {
    filterItems: {
      handler() {
        // skip to run initial timing
        if(!this.initialized) {
          this.initialized = true;
          return
        }

        this.page = 1;
        this.fetchResults(this.page);
      },
      // watch inside of the object
      deep: true
    },
  },

  mounted() {
    // parse query params
    const url = new URL(window.location.href);
    const urlParams = url.searchParams

    // set page from query params
    const page = url.searchParams.get('page');
    if (page != null) {
      this.page = parseInt(page);
    }

    this.resultCount = parseInt(this.defaultCount);
    this.filterElements = JSON.parse(this.filterElementsJson);
    this.filterElements.forEach(elem => {
      // set filterItems from query params
      let values = [];
      if (urlParams.has(elem.key)) {
         values = urlParams.get(elem.key).split('+').map(Number);
      }

      Vue.set(this.filterItems, elem.key, values);
      Vue.set(this.filterStatus, elem.key, 'partial');
    });

    this.contents = JSON.parse(this.defaultContents);
  }
}
</script>

<style lang="scss" scoped>
</style>
