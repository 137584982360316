export default () => {
  document.addEventListener("turbolinks:load", () => {
    // Temporary
    return;

    const element = document.querySelector('.user .header .tags-center');

    if (!element) {
      return;
    }

    const leftBtn = document.querySelector('.user .header .tags-left');
    const rightBtn = document.querySelector('.user .header .tags-right');

    // Disable scroll
    element.addEventListener('touchmove', (event) => {
      event.preventDefault();
    } , { passive: false });

    element.addEventListener('mousewheel', (event) => {
      event.preventDefault();
    } , { passive: false });

    leftBtn.addEventListener('click', () => {
      const currentPosition = element.scrollLeft;
      let newPosition = currentPosition - element.clientWidth;

      if (newPosition < 0) {
        newPosition += element.scrollWidth / 2;
      }
      element.scrollLeft = newPosition;
    });

    rightBtn.addEventListener('click', () => {
      const currentPosition = element.scrollLeft;
      let newPosition = currentPosition + element.clientWidth;

      if (newPosition > element.scrollWidth - element.clientWidth) {
        newPosition -= element.scrollWidth / 2;
      }
      element.scrollLeft = newPosition;
    });
  });
};
