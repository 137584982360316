<template>
  <div class="admin-select flex-column flex-justify-center">
    <div class="mb-16 width-400 m-auto text-left pl-8">

      <label :class="required == 'true' ? 'label-required' : ''">{{ attributeName }}</label>
    </div>

    <div v-for="(_item, i) in selectedItems" class="width-400 m-auto flex-justify-between flex-row">
      <div class="select width-350">
        <select v-model="selectedItems[i].targetId" :name="formTargetIdName(i)" class="width-100p">
          <option v-for="option in options" :value="option[0]">{{ option[1] }}</option>
        </select>
        <input type="hidden" :name="formIdName(i)" v-model="selectedItems[i].id" v-if="selectedItems[i].id">
      </div>
      <div v-if="i !== 0" class="width-50 text-left pt-8 pl-8">
        <span class="icon" v-if="i !== 0" @click="removeItem(i)">
          <i class="fas fa-times-circle"></i>
        </span>
      </div>
    </div>

    <div class="width-400 m-auto text-right mt-16">
      <span class="button is-small" @click="addItem">Add</span>
    </div>

    <div v-for="(_item, i) in destroyedItems">
        <input type="hidden" :name="formIdName(i + selectedItems.length)" v-model="destroyedItems[i].id">
        <input type="hidden" :name="formTargetIdName(i + selectedItems.length)" v-model="destroyedItems[i].targetId">
        <input type="hidden" :name="formDestroyName(i + selectedItems.length)" value="true">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    attributeName: String,
    defaultSelectedItems: String,
    formKey: String,
    formName: String,
    optionJson: String,
    required: String
  },

  data() {
    return {
      destroyedItems: [],
      selectedItems: [{ 'id': null, 'targetId': null }]
    }
  },

  computed: {
    options() {
      const options =  JSON.parse(this.optionJson);
      options.unshift([null, 'Please select']);
      return options;
    },
  },

  methods: {
    addItem() {
      this.selectedItems.push({ 'id': null, 'targetId': null });
    },

    formDestroyName(index) {
      return `${this.formName}[${index}][_destroy]`;
    },

    formIdName(index) {
      return `${this.formName}[${index}][id]`;
    },

    formTargetIdName(index) {
      return `${this.formName}[${index}][${this.formKey}]`;
    },

    removeItem(index) {
      const removedItem = this.selectedItems.splice(index, 1)[0];
      if (removedItem.id) {
        this.destroyedItems.push(removedItem);
      }
    }
  },

  mounted() {
    if (JSON.parse(this.defaultSelectedItems).length > 0) {
      this.selectedItems.pop(); // Removes default item
      JSON.parse(this.defaultSelectedItems).forEach(item => {
        this.selectedItems.push({ 'id': item[0], 'targetId': item[1] })
      });
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
