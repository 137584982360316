export default () => {
  document.addEventListener("turbolinks:load", () => {
    const notifications = document.querySelectorAll('.notification .delete') || [];

    for (let i = 0; i < notifications.length; ++i) {
      const elem = notifications[i];
      const notification = elem.parentNode;

      elem.addEventListener('click', () => {
        notification.parentNode.removeChild(notification);
      });
    };

    const publicNotifications = document.querySelectorAll('.public-notification .delete') || [];

    for (let i = 0; i < publicNotifications.length; ++i) {
      const elem = publicNotifications[i];
      const notification = elem.parentNode.parentNode.parentNode;

      elem.addEventListener('click', () => {
        notification.parentNode.removeChild(notification);
      });
    };

    // resend confirmation in flash
    const resendLink = document.querySelector('.flash .resend-email')
    const axios = require('axios');
    if (resendLink) {
      resendLink.addEventListener('click', () => {
        const target = document.querySelector('.flash .resend-email .resend-target')
        axios.post('/user/resend_confirmation', { email: target.innerText })
        .then(response => {
        })
        .catch(error => {
          console.log(error)
        });
      })
    }

    // resend password in flash
    // const resendForm = document.querySelector('.flash #new_user_account')
    // if (resendForm) {
    //   const link = document.querySelector('.flash .resend-email')
    //   link.addEventListener('click', () => {
    //     resendForm.submit()
    //   })
    // }
  });
};
