export default () => {
  document.addEventListener("turbolinks:load", () => {
    const tabs = document.querySelectorAll('.user-tabs .user-tab-item');

    if (!tabs) {
      return;
    }

    const contents = document.querySelectorAll('.user-contents .user-content');

    tabs.forEach((tab) => {
      tab.addEventListener('click', () => {
        tabs.forEach((tab) => {
          tab.classList.remove('selected')
        });

        const selectedTab = tab.className.split(' ').pop();
        tab.classList.add('selected');

        contents.forEach((content) => {
          if (content.classList.contains(selectedTab)) {
            content.classList.add('selected')
          } else {
            content.classList.remove('selected')
          }
        });
      });
    });
  });
};
