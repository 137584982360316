import Vue from 'vue/dist/vue.esm'

import UserList from '../components/user_list.vue'

export default () => {
  document.addEventListener("turbolinks:load", () => {
    if (document.getElementById('user-list')) {
      const chat = new Vue({
        el: '#user-list',
        components: { UserList },
      });
    }

    if (document.getElementById('user-list-like')) {
      const chat = new Vue({
        el: '#user-list-like',
        components: { UserList },
      });
    }

    if (document.getElementById('user-list-collection')) {
      const chat = new Vue({
        el: '#user-list-collection',
        components: { UserList },
      });
    }
  });
};
