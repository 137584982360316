export default () => {
  document.addEventListener("turbolinks:load", () => {
    const collections = document.querySelectorAll('.user .collection-icon')

    if (!collections) {
      return;
    }

    const axios = require('axios');
    const collection_image = '/images/icons/add_to_collection_button.svg'
    const collected_image = '/images/icons/added_to_collection_button.svg'

    collections.forEach((collection) => {
      collection.addEventListener('click', () => {
        const type = collection.dataset.type
        const id = collection.dataset.id
        const action = collection.dataset.action

        if (action != 'remove' && action !== 'add') {
          return
        }

        if (action == 'remove') {
          collection.dataset.action = 'add'
          collection.querySelector('img').src = collection_image
        } else if (action == 'add') {
          collection.dataset.action = 'remove'
          collection.querySelector('img').src = collected_image
        }

        axios.post('/collections', { id: id, type: type, required_action: action })
        .then(response => {
        })
        .catch(error => {
          console.log(error)
        });
      })
    })
  })
}
