export default () => {
  document.addEventListener("turbolinks:load", () => {
    const buttons = document.querySelectorAll('.user.tags-controller.index-action .tags .link-inner') || [];
    buttons.forEach((button) => {
      button.addEventListener('click', () => {
        const tagsSP = button.parentNode.parentNode.querySelector('.tag-container-sp');
        const tagsAll = button.parentNode.parentNode.querySelector('.tag-container');

        tagsSP.classList.add('hidden');
        button.classList.add('hidden');
        tagsAll.classList.remove('hidden');
      });
    });
  });
};
