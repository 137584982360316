export default () => {
  document.addEventListener("turbolinks:load", () => {
    const seeMoreItems = document.querySelectorAll('.see-more-container');

    if (seeMoreItems) {
      seeMoreItems.forEach((seeMoreItem) => {
        const part = seeMoreItem.querySelector('.see-more-part')
        if (!part) {
          return
        }

        part.addEventListener("click", () => {
          const target = seeMoreItem.querySelector('.see-more-target')
          target.innerText = seeMoreItem.querySelector('.see-more-text').innerText

          part.style.display = 'none'
        })
      })
    }
  });
};
