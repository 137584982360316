<template>
  <div class='user-multi-tag-review'>
    <div v-if="hasCandidates">
      <div class="text-left">
        <span> New Tags</span>
      </div>

      <div class="mt-8 flex-row" v-for="(candidate, index) in candidates">
        <span class="input width-80p review-item">{{ candidate }}</span>
        <button class="delete ml-8 mt-8" @click="removeCandidate(index)"></button>
      </div>

      <div class="text-right mt-8 mb-24">
        <a class="button" href='/admin/tags/new' target='_blank'>Create new</a>
      </div>
    </div>

    <multiselect
      v-model="values"
      :options="options"
      :multiple="true"
      :searchable="true"
      @open="fetchOptions">
    </multiselect>

    <input type="hidden" :name="fieldName" :value="JSON.stringify(values)">
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
const axios = require('axios');

export default {
  props: {
    optionsJson: String,
    valuesJson: String,
    fieldName: String,
  },

  computed: {
    hasCandidates () {
      return this.candidates.length > 0
    }
  },

  components: {
    Multiselect
  },

  data () {
    return {
      options: [],
      values: [],
      candidates: [],
      fetchUrl: '/admin/tags/options'
    }
  },

  methods: {
    removeCandidate (index) {
      this.candidates.splice(index, 1)
    },
    fetchOptions () {
      axios.get(this.fetchUrl, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }})
        .then(response => {
          console.log(response)
            response.data.data.forEach(content => {
              if (!this.options.includes(content)) {
                this.options.push(content)
              }
            })
        })
        .catch(error => {
          console.log(error);
        });
    },
  },

  mounted() {
    if (this.options) {
      this.options = JSON.parse(this.optionsJson)
    }
    if (this.valuesJson) {
      for (const value of JSON.parse(this.valuesJson)) {
        if (this.options.includes(value)) {
          this.values.push(value)
        } else {
          this.candidates.push(value)
        }
      }
    }
  },
}

</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
</style>
