import Vue from 'vue/dist/vue.esm'
import SearchPageComponent from '../components/search_page.vue'

export default () => {
  document.addEventListener("turbolinks:load", () => {
    if (document.getElementById('search-page-component')) {
      const elem = new Vue({
        el: '#search-page-component',
        components: { SearchPageComponent }
      });
    }
  });
};
