<template>
  <div class='items user-list'>
    <div class="item" v-for="user in users">
      <div class="item-image">
        <a :href="userLikePath(user.id)">
          <img :src="user.thumb_url" />
        </a>
      </div>
      <div class="item-name">
        <a :href="userPath(user.id)">
          <span>{{ user.name }}</span>
        </a>
      </div>
      <div class="item-tag">
        <a :href="searchPath(user.user_name)">
          <span>{{ user.user_name }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios');

export default {
  props: {
    parentClass: String,
  },
  data() {
    return {
      users: [],
      targetId: '',
      targetType: '',
      dataType: '',
    }
  },

  methods: {
    searchPath(userName) {
      return `/search?q=${userName}`
    },
    userPath(userId) {
      return `/users/${userId}`
    },
    userLikePath(userId) {
      return `/users/${userId}#like`
    },
    setAttr() {
      const targetData = document.querySelector(this.baseSelector)
      if (targetData) {
        this.targetId = targetData.querySelector('.user-list-target-id').innerText
        this.targetType = targetData.querySelector('.user-list-target-type').innerText
        this.dataType = targetData.querySelector('.user-list-data-type').innerText
      }
    },
    fetchUsers() {
      const url = this.fetchUrl

      if (!url) {
        return
      }

      axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }})
        .then(response => {
          this.users = response.data.data
        })
        .catch(error => {
          console.log(error);
        });
    },
  },
  computed: {
    baseSelector() {
      return `.user-list-data.${this.parentClass}`
    },
    fetchUrl() {
      if (this.targetType && this.targetId) {
        return `/${this.targetType}/${this.targetId}/${this.dataType}`
      } else {
        return false
      }
    },
  },
  mounted() {
    this.setAttr()
    if (this.targetId) {
      this.fetchUsers()
    }

    // Set monitoring
    const targetNode = document.querySelector(this.baseSelector)
    if (targetNode) {
      const callback = (mutationsList, observer) =>  {
        mutationsList.forEach((mutation) => {
          const targetData = mutation.target.parentNode

          this.targetId = targetData.querySelector('.user-list-target-id').innerText
          this.targetType = targetData.querySelector('.user-list-target-type').innerText
          this.fetchUsers()
        })
      }
      const observer = new MutationObserver(callback)
      const config = {
        childList: true,
        attributes: true,
        subtree: true
      }
      observer.observe(targetNode, config)
    }
  },
}
</script>
