export default () => {
  document.addEventListener("turbolinks:load", () => {
    const image = document.querySelector('.admin input.upload-image');

    if (image) {
      const preview = document.getElementById('image-preview');
      image.addEventListener('change', () => {
        const file = image.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
          preview.style.backgroundImage = 'url(' + e.target.result + ')';
        }
        reader.readAsDataURL(file);
      });
    }
  });
};
