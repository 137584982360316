<template>
  <section>
    <b-field>
      <b-autocomplete
        v-model="query"
        :data="filteredDataArray"
        clearable
        @typing="fetchOptions"
        @select="option => selected = option">
        <template #empty>No results found</template>
      </b-autocomplete>
      <input type="hidden" name="q" id="search-query" :value="query">
    </b-field>
  </section>
</template>

<script>
const axios = require('axios');

export default {
  props: {
    defaultQuery: String,
  },
  data() {
    return {
      data: [],
      query: '',
      selected: null
    }
  },

  methods: {
    fetchOptions (newVal) {
      const fetchUrl =  `/search_items/options?q=${newVal}`
      axios.get(fetchUrl, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }})
        .then(response => {
          this.data = response.data.data
        })
        .catch(error => {
          console.log(error);
        });
    },
  },
  computed: {
    filteredDataArray() {
      return this.data.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(this.query.toLowerCase()) >= 0
      })
    }
  },
  mounted() {
    this.query = this.defaultQuery
  },
}
</script>
